import eshopimg from '../../assets/pyramid_shop.JPG'
import wip from '../../assets/wip.png'
import { Grid, Container, Fade } from "@mui/material";
import PortfolioCardItem from '../../components/portfolio-card-item/portfolio-card-item.component';
import { useState, useEffect } from 'react';

const Portfolio = () => {

    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
    }, []);

    const portfolioItems = [
        { title: "E-shop template", imgLink: eshopimg, description: "Built with React, Firebase, Netlify", projectLink: "http://pyramid-shop.clementhabib.fr/" },
        { title: "Subscriptions management platform", imgLink: wip, description: "Will be built with Java/Spring, React, MySQL", projectLink: null },
        { title: "Dashboard on Hellfest tickets reselling", imgLink: wip, description: "Will be built with C#/.NET, React, SQL Server, Azure", projectLink: null }
    ]

    return (
        <Container justifycontent='center'>
            <Fade in={show} timeout={500}>
                <Grid container textAlign='center' rowSpacing={4} columnSpacing={{ sm: 2, md: 3 }} 
                    marginTop='2em' display={{xs: 'block', md: 'flex'}}>
                    {portfolioItems.map(({ title, imgLink, description, projectLink } ) => 
                        <PortfolioCardItem 
                            key={title}
                            title={title}
                            imgLink={imgLink}
                            description={description}
                            projectLink={projectLink} />
                        )}
                </Grid>
            </Fade>
        </Container>

    );
};

export default Portfolio;
