import DownloadCV from '../../components/download-cv/download-cv.component';
import { Container } from '@mui/material';
import SkillsItems from '../../components/skills-items/skills-items.component';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import ExperienceEducationItems from '../../components/experience-education-items/experience-education-items.component';
const CVItems = () => {

    const educationItems = [
        {primaryText: "EPSI, Nantes - Computer Science and Information System Expert Degree", dates: "2018-2020", icon: <LocationCityOutlinedIcon /> },
        {primaryText: "University of Sciences, Nantes - MIAGE Licence", dates: "2017-2018", icon: <LocationCityOutlinedIcon /> },
        {primaryText: "University Institute Of Technology, Nantes - Computer Science DUT", dates: "2015-2017", icon: <LocationCityOutlinedIcon />},
        {primaryText: "Notre-Dame de Toutes-Aides High School, Nantes - High School Diploma", dates: "2015", icon: <LocationCityOutlinedIcon />},
    ]

    const experiences = [
        {primaryText: "Cegid/Talentsoft - Technical Support Consultant", dates: "Since December 2020", icon: <LocationCityOutlinedIcon /> },
        {primaryText: "Open Group - Java Developer", dates: "April 2018 - August 2020", icon: <LocationCityOutlinedIcon /> },
        {primaryText: "U GIE-IRIS - Java Developer", dates: "April 2017 - July 2017", icon: <LocationCityOutlinedIcon />}
    ]

    const skills = [
        { name: 'Coding and web technologies', details: 'C#/.NET, Java/JEE, C, C++, Javascript, PowerShell, HTML, CSS, Cobol, PHP, Python, SQL, Bootstrap, Symfony, Struts, TCP/IP, DNS, Git' },
        { name: 'Databases', details: 'SQL Server, MySQL, Oracle, DB'},
        { name: 'Conception', details: 'REST, SOAP'},
        { name: 'Monitoring and orchestration', details: 'ELK, SentryOne, New Relic, Talend, VisualCron'},
        { name: 'Softwares and IDEs', details: 'Visual Studio, Visual Studio Code, Eclipse, Jupyter, Office Suite'},
        { name: 'OS', details: 'Windows, Linux, Android'}
    ]

    return (
        <Container>
            <ExperienceEducationItems listTitle={"Education"} itemsToDisplay={educationItems} rootIcon={<SchoolIcon />}/>
            <ExperienceEducationItems listTitle={"Experience"} itemsToDisplay={experiences}  rootIcon={<WorkIcon />} />
            <SkillsItems skillsToDisplay={skills} />
            <DownloadCV />
        </Container>
    );
};

export default CVItems;