import './App.css';
import Home from "./routes/home/home.component";
import Navigation from "./routes/navigation/navigation.component";
import { Routes, Route } from "react-router-dom";
import Portfolio from './routes/portfolio/portfolio.component';
import CVItems from './routes/cv-items/cv-items.component';

function App() {
  return (
    <Routes>
    <Route path="/" element={<Navigation />}>
      <Route index element={<Home />} />
      <Route path="/resume" element={<CVItems />}></Route>
      <Route path="portfolio" element={<Portfolio />} />
    </Route>
  </Routes>
  );
}

export default App;
