import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Collapse, Fade } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useState, useEffect } from 'react';
import CVListItem from '../cv-list-item/cv-list-item.component';


const ExperienceEducationItems = ({ listTitle, itemsToDisplay, rootIcon }) => {

    const [open, setOpen] = useState(true);

    const handleClick = () => {
    setOpen(!open);
    };

    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
    }, []);

    return ( 
            <Fade in={show} timeout={500} unmountOnExit>
                <List
                sx={{ width: '100%', bgcolor: 'background.paper', mt: '2em' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                >
                <ListItemButton onClick={handleClick}>
                    <ListItemIcon>
                    {rootIcon}
                    </ListItemIcon>
                    <ListItemText primary={listTitle} />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        {itemsToDisplay.map((itemToDisplay) => 
                                    <CVListItem key={itemToDisplay.primaryText} item={itemToDisplay} /> 
                                    )}
                        </List>
                    </Collapse>
                </List>
            </Fade>
    );
};

export default ExperienceEducationItems;