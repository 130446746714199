import { Fade, Collapse, Typography, Button, ButtonGroup, Container, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

const Welcome = () => {

    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
    }, []);

    return (
        <Container style={{marginTop: '2em'}}>
            <Fade in={show} timeout={1000}  unmountOnExit>
                <Typography variant="h3" textAlign='center' mt='2em'>
                Welcome, my name is Clément.
                </Typography>
            </Fade>
            <Collapse in={show} timeout={1200}  unmountOnExit>
                <Box textAlign='center'>
                    <ButtonGroup 
                    variant="contained" 
                    aria-label="choice-home-menu" 
                    style={{marginTop: '2em'
                            }}>
                        <Link to="/resume">
                            <Button>Go To Resume</Button>
                        </Link>
                        <Link to="/portfolio">
                            <Button>Go To Portfolio</Button>
                        </Link>
                    </ButtonGroup>
                </Box>
                <Box textAlign='center' marginTop='2em'>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/cl%C3%A9ment-habib-5a7997151/">  
                        <LinkedInIcon fontSize="large" color="primary" />
                    </a>
                    <a href="mailto:clement.habib@protonmail.com">  
                        <EmailIcon fontSize="large" color="primary" />
                    </a>
                </Box>
            </Collapse>
        </Container>
    );
};

export default Welcome;