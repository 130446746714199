import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Collapse, Fade } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useState, useEffect } from 'react';
import ComputerIcon from '@mui/icons-material/Computer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const SkillsItems = ( { skillsToDisplay }) => {

    const [open, setOpen] = useState(true);

    const handleClick = () => {
    setOpen(!open);
    };

    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
    }, []);

    return (
        <Fade in={show} timeout={500} unmountOnExit>
            <List
            sx={{ width: '100%', bgcolor: 'background.paper', mt: '2em' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            >
                <ListItemButton onClick={handleClick}>
                    <ListItemIcon>
                    <ComputerIcon />
                    </ListItemIcon>
                    <ListItemText primary="Skills" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="skills-table">
                            <TableBody>
                            {skillsToDisplay.map((skill) => (
                                <TableRow
                                key={skill.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">{skill.name}</TableCell>
                                    <TableCell align="left">{skill.details}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Collapse>
            </List>  
        </Fade>
    );
};

export default SkillsItems;