import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import NavButton from '../../components/nav-button/nav-button.component';
import { Link, Outlet } from 'react-router-dom';
import avatarLink from '../../assets/avatar.jpg'; 
import AvatarPicture from '../../components/avatar-picture/avatar-picture.component';


function Navigation() {

  return (
    <>
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
            <Link to="/">
                <AvatarPicture avatarLink={avatarLink} style={{marginRight: '1em'}}/>
            </Link>
            
            <Typography
                variant="h6"
                noWrap
                component="span"
                sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'DM Sans',
                fontWeight: 300,
                letterSpacing: '.2rem',
                color: 'inherit'
                }}
            >                
            <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
                Clément Habib
            </Link>
            </Typography>

          <Box sx={{ flexGrow: 1, display: 'flex' , paddingLeft: '3em'}}>
            <NavButton name="Resume" link="/resume" />
            <NavButton name="Portfolio" link="/portfolio" />
          </Box>
          
        </Toolbar>
      </Container>
    </AppBar>
    <Outlet />
    </>
  );
}
export default Navigation;