import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const NavButton = ({name, link}) => {
    return (
        <Link to={link} style={{color: 'inherit', textDecoration: 'none'}}>
            <Button
            sx={{ my: 2, color: 'white', display: 'block' }}>
            {name}
        </Button>
      </Link>
    );
};

export default NavButton;