import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const CVListItem = ({ item }) => {
    return (
        <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
            {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.primaryText} />
            {item.dates}
        </ListItemButton>        
    );
};

export default CVListItem;