import { Link } from 'react-router-dom';
import pdfCV from '../../files/Clément_Habib_CV.pdf'
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import { Grow } from '@mui/material';
import { useState, useEffect } from 'react';

const DownloadCV = () => {

    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
    }, []);

    return (
            <Grow
            in={show}
            style={{ transformOrigin: '0 0 0' }}
            {...(show ? { timeout: 800 } : {})}
        >
            <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    marginTop: '2em'
                }}>            
                <Link to={pdfCV} target="_blank" download style={{textDecoration: 'none', color: 'black'}}>
                    <GetAppOutlinedIcon /> 
                </Link>
                    Download resume (FR Version)
                </div>
            </Grow>
    );
};

export default DownloadCV;