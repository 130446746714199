import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';

const PortfolioCardItem = ({ title, imgLink, description, projectLink }) => {

    const isButtonDisabled = projectLink ? false : true;

    return (
        <Grid item xs={6}>
            <Card sx={{ maxWidth: 345, height: 400 }}>
                <CardMedia
                    sx={{ height: 140 }}
                    image={imgLink}
                    title={title}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                    {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                    {description}
                    </Typography>
                </CardContent>
                <CardActions>
                    <a target="_blank" rel="noopener noreferrer" href={projectLink}>
                    <Button disabled={isButtonDisabled} size="small">Visit</Button>
                    </a>
                </CardActions>
            </Card>
        </Grid>
    );
};

export default PortfolioCardItem;