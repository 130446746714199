import Avatar from '@mui/material/Avatar';
import { StyledBadge } from './avatar-picture.styles';

const AvatarPicture = ({avatarLink, ...props}) => {

    return (
        <div {...props}>
        <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
        >
        <Avatar alt="Clément Habib" src={avatarLink} sx={{ marginRight: '1%', width: 56, height: 56  }}/>
        </StyledBadge> 
        </div>
    );
};

export default AvatarPicture;